.sign-in-button button{
    background-color: black;
}

.sign-in-button button:hover{
    background-color: red;
    transition: all 1s;
}

@media only screen and (max-width: 575px) {
    .sign-in-button{
        margin-bottom: 100px;
    }
  }

  @media only screen and (min-width: 575px) {
    .login-page{
        height: 100vh;
    }
    .media-me{
        height: 100vh;
    }
  }