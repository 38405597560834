.image-vibe {
  width: 100%;
  height: 300px;
  background: linear-gradient(
    300deg,
    deepskyblue,
    darkviolet,
    #8bdbbd,
    #dc3ed7,
    #aea91a
  );
  background-size: 300% 300%;
  animation: gradient-animation 20s ease infinite;
}

.display-me button{
  background-color: #000;
  color: #fff;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
